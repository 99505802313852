var render = function render(){
  var _vm$brand, _vm$brand$subscriptio, _vm$brand2, _vm$brand2$subscripti, _vm$brand3, _vm$brand3$subscripti, _vm$brand4, _vm$brand4$subscripti, _vm$brand5, _vm$brand5$subscripti, _vm$brand6, _vm$brand6$subscripti, _vm$brand7, _vm$brand7$subscripti;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "px-5 ml-sm-3 mt-10",
    attrs: {
      "disabled": _vm.disabled,
      "flat": ""
    }
  }, [_vm.brandUserRolesAdminAccessErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.brandUserRolesAdminAccessErrorMessage) + " ")]) : _vm._e(), _vm.subscriptionErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.subscriptionErrorMessage) + " ")]) : _vm._e(), _vm.subscriptionMessageForManualPaymentMethod && !_vm.brandDeletionMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.subscriptionMessageForManualPaymentMethod) + " ")]) : _vm._e(), _vm.trialPeriod ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "info",
      "color": "green"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yourTrialPeriodWillEndIn')) + " " + _vm._s(_vm.remainingTrialPeriod) + ". ")]) : _vm._e(), !!_vm.brandDeletionMessage ? _c('v-alert', {
    staticClass: "col-12 text-center",
    attrs: {
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.brandDeletionMessage) + " ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" : support@cm-technology.io ")])]) : _vm._e(), _vm.brandSubscription ? _c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.$t('Subscription')))]), ((_vm$brand = _vm.brand) === null || _vm$brand === void 0 ? void 0 : (_vm$brand$subscriptio = _vm$brand.subscription) === null || _vm$brand$subscriptio === void 0 ? void 0 : _vm$brand$subscriptio.price) === 0 ? _c('span', [_vm._v(" (" + _vm._s(_vm.$t('free')) + ")")]) : _vm._e(), _c('v-spacer'), !_vm.brandDeletionMessage && !_vm.subscriptionMessageForManualPaymentMethod ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalCancelSubscription = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-lock ")]), ((_vm$brand2 = _vm.brand) === null || _vm$brand2 === void 0 ? void 0 : (_vm$brand2$subscripti = _vm$brand2.subscription) === null || _vm$brand2$subscripti === void 0 ? void 0 : _vm$brand2$subscripti.price) === 0 ? [_vm._v(" " + _vm._s(_vm.$t('disabledThisWarehouse')) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('CancelSubscription')) + " ")]], 2) : _vm.brandDeletionMessage && !_vm.subscriptionMessageForManualPaymentMethod ? _c('v-btn', {
    staticClass: "text-none ml-3",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalReactivateSubscription = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-lock-open ")]), _vm._v(" " + _vm._s(_vm.$t('ReactivateSubscription')) + " ")], 1) : _vm._e()], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "450px"
    },
    model: {
      value: _vm.modalCancelSubscription,
      callback: function ($$v) {
        _vm.modalCancelSubscription = $$v;
      },
      expression: "modalCancelSubscription"
    }
  }, [_c('v-card', [((_vm$brand3 = _vm.brand) === null || _vm$brand3 === void 0 ? void 0 : (_vm$brand3$subscripti = _vm$brand3.subscription) === null || _vm$brand3$subscripti === void 0 ? void 0 : _vm$brand3$subscripti.price) === 0 ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('yourWarehouseIsFreeDeactivate')) + " ? ")]) : _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('CancelSubscription')) + " ? ")]), _c('v-divider'), ((_vm$brand4 = _vm.brand) === null || _vm$brand4 === void 0 ? void 0 : (_vm$brand4$subscripti = _vm$brand4.subscription) === null || _vm$brand4$subscripti === void 0 ? void 0 : _vm$brand4$subscripti.price) === 0 ? _c('v-card-text', {
    staticClass: "body-1 py-4 text-justify"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('confirmDeactivate')))])]) : _c('v-card-text', {
    staticClass: "body-1 py-4 text-justify"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('confirmCancelSubscription')) + " :")]), _vm.brandSubscription ? _c('div', {
    staticClass: "font-weight-bold text-center pt-2"
  }, [!_vm.brandSubscription.endTrialPeriod ? _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.readableNextBillingDate) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.endTrialPeriod) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "pt-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('confirmCancelSubscription2')) + " ")])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalCancelSubscription = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.deactivateBrandSubscription
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "450px"
    },
    model: {
      value: _vm.modalReactivateSubscription,
      callback: function ($$v) {
        _vm.modalReactivateSubscription = $$v;
      },
      expression: "modalReactivateSubscription"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('ReactivateSubscription')) + " ? ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-1 py-4 text-justify"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('confirmContinueSubscription')) + " :")]), _vm.brandSubscription ? _c('div', {
    staticClass: "font-weight-bold text-center pt-2"
  }, [!_vm.brandSubscription.endTrialPeriod ? _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.readableNextBillingDate) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.endTrialPeriod) + " ")])]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalReactivateSubscription = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.reactivateBrandSubscription
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.brandSubscription ? _c('v-card-text', {
    staticClass: "pt-0"
  }, [_c('v-row', {
    staticClass: "body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Offer')) + " : ")]), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.getSubscriptionTitle(_vm.brandSubscription.plan))) + " ")])])]), ((_vm$brand5 = _vm.brand) === null || _vm$brand5 === void 0 ? void 0 : (_vm$brand5$subscripti = _vm$brand5.subscription) === null || _vm$brand5$subscripti === void 0 ? void 0 : _vm$brand5$subscripti.price) > 0 ? _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Recurrence')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.brandSubscription.recurrence)) + " ")])])]) : _vm._e(), _vm.brandSubscription.payment_method !== 'MANUAL' && ((_vm$brand6 = _vm.brand) === null || _vm$brand6 === void 0 ? void 0 : (_vm$brand6$subscripti = _vm$brand6.subscription) === null || _vm$brand6$subscripti === void 0 ? void 0 : _vm$brand6$subscripti.price) > 0 ? _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('PaymentMethod')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.brandSubscription.payment_method)) + " ")])])]) : _vm._e(), _vm.brandSubscription.payment_method !== 'MANUAL' && ((_vm$brand7 = _vm.brand) === null || _vm$brand7 === void 0 ? void 0 : (_vm$brand7$subscripti = _vm$brand7.subscription) === null || _vm$brand7$subscripti === void 0 ? void 0 : _vm$brand7$subscripti.price) > 0 ? [!_vm.trialPeriod ? _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('nextBillingDate')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.readableNextBillingDate) + " ")])])]) : _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('endTrialPeriod')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.readableNextBillingDate) + " ")])])])] : _vm._e(), _vm.brandSubscription && _vm.brandSubscription.discountsArray.length > 0 ? _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Discount')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.discountsArray[0].discount) + " ")])])]) : _vm._e(), _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('PriceET')) + " : ")]), _vm.brandSubscription.discountsArray.length === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.brandSubscription.price.toFixed(2), 'currency')) + " ")]) : _c('span', [_c('span', {
    staticClass: "text-strikethrough"
  }, [_vm._v(" " + _vm._s(_vm.$n(_vm.brandSubscription.price.toFixed(2), 'currency')) + " ")]), _c('span', {
    staticClass: "ml-2 title orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$n(_vm.brandSubscription.discountsArray[0].price, 'currency')) + " ")])])])]), _vm.brandSubscription.purchaseOrder ? _c('v-row', {
    staticClass: "pt-3 body-1"
  }, [_c('div', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('purchaseOrder')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.brandSubscription.purchaseOrder) + " ")])])]) : _vm._e()], 2) : _vm._e()], 1), _vm.brandSubscription && _vm.brandSubscription.discountsArray.length > 0 ? _c('v-card', {
    staticClass: "mt-10",
    attrs: {
      "flat": ""
    }
  }, [_vm.brandSubscription.recurrence === 'MONTHLY' ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('yourMonthlyDiscounts')) + " ")]) : _vm._e(), _vm.brandSubscription.recurrence === 'YEARLY' ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('yourYearlyDiscounts')) + " ")]) : _vm._e(), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersDiscounts,
      "hide-default-footer": true,
      "items": _vm.brandSubscription.discountsArray,
      "mobile-breakpoint": "0",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', {
          class: {
            'font-weight-bold': item === _vm.brandSubscription.discountsArray[0]
          }
        }, [_c('td', [_vm._v(" " + _vm._s(item.discount) + " ")]), _c('td', [_vm._v(_vm._s(item.price) + "€")]), item.duration !== -1 ? _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.duration) + " ")]) : _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('lifetime')) + " ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.start) + " ")]), item.duration !== -1 ? _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.end) + " ")]) : _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" - ")])])];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1), _vm.subscriptionInvoices && _vm.subscriptionInvoices.length > 0 ? _c('v-card', {
    staticClass: "px-5 ml-sm-3 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('invoices')) + " ")]), _c('v-card-text', [_vm.subscriptionInvoices ? _c('v-data-table', {
    attrs: {
      "headers": _vm.headersInvoices,
      "items": _vm.subscriptionInvoices,
      "items-per-page": 10,
      "sort-by": "date",
      "mobile-breakpoint": "0",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.date`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [[_c('span', [_vm._v(" " + _vm._s(item.readableCreated) + " ")])]];
      }
    }, {
      key: `item.price`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [[_c('span', [_vm._v(_vm._s(item.readablePrice) + " ")])]];
      }
    }, {
      key: `item.download`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [[_c('a', {
          ref: `download${item._id}`,
          staticClass: "d-none"
        }), _c('v-btn', {
          staticClass: "text-none white--text",
          attrs: {
            "color": "orange",
            "target": "_blank",
            "loading": item.loadingPdf,
            "disabled": item.disabled,
            "ripple": false
          },
          on: {
            "click": function ($event) {
              return _vm.getSubscriptionInvoicePDF(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-download ")]), _vm._v(" PDF ")], 1)]];
      }
    }, {
      key: `item.status`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm.brandSubscription && _vm.brandSubscription.payment_method === 'CARD' ? [item.paymentStatus === 'PAID' ? [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "color": "green"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                attrs: {
                  "color": "green"
                }
              }, [_vm._v(" fas fa-check ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Paid')) + " ")])])] : item.paymentStatus === 'WAITING' ? [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var on = _ref7.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "color": "black"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                attrs: {
                  "color": "black"
                }
              }, [_vm._v(" fas fa-ellipsis-h ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('InProgress')) + " ")])])] : [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var on = _ref8.on;
              return [_c('div', _vm._g({
                staticClass: "v-icon"
              }, on), [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": "red"
                }
              }, [_vm._v(" far fa-credit-card ")]), _c('v-icon', {
                staticClass: "mr-5",
                attrs: {
                  "color": "red"
                }
              }, [_vm._v(" fas fa-exclamation-triangle ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ErrorPayment')) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('v-badge', _vm._g({
                staticClass: "mr-5",
                attrs: {
                  "overlap": "",
                  "bordered": "",
                  "icon": "fas fa-pen"
                }
              }, on), [_c('v-btn', _vm._g({
                attrs: {
                  "small": "",
                  "outlined": "",
                  "color": "info"
                },
                on: {
                  "click": function ($event) {
                    return _vm.manualPayment(item);
                  }
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" far fa-credit-card ")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ManualPayment')) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var on = _ref10.on;
              return [_c('v-badge', _vm._g({
                attrs: {
                  "overlap": "",
                  "bordered": "",
                  "icon": "fas fa-plus"
                }
              }, on), [_c('v-btn', _vm._g({
                attrs: {
                  "small": "",
                  "outlined": "",
                  "color": "info",
                  "to": {
                    name: 'AdministrationPaymentMethods',
                    params: {
                      brandUUID: `${_vm.$appConfig.currentBrand}`
                    }
                  }
                }
              }, on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" far fa-credit-card ")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('AddOrChangeCard')) + " ")])])]] : _vm._e()];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.client_key ? _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.modalPayment,
      callback: function ($$v) {
        _vm.modalPayment = $$v;
      },
      expression: "modalPayment"
    }
  }, [_c('v-card', {
    attrs: {
      "min-height": "300px"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('PayByCard')) + " ")]), _c('v-btn', {
    staticStyle: {
      "position": "absolute",
      "right": "15px"
    },
    attrs: {
      "icon": "",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.modalPayment = false;
        _vm.client_key = null;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-times")])], 1)], 1), _c('v-card-text', [_c('stripe-payment', {
    attrs: {
      "api-key": _vm.$stripeConfig.pk,
      "client-secret": _vm.client_key
    },
    on: {
      "paid": _vm.paymentFinished
    }
  })], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }