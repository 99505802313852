<template>
  <div>
    <v-card
      :disabled="disabled"
      flat
      class="px-5 ml-sm-3 mt-10"
    >
      <v-alert
        text
        outlined
        type="info"
        v-if="brandUserRolesAdminAccessErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ brandUserRolesAdminAccessErrorMessage }}
      </v-alert>
      <v-alert
        dismissible
        text
        outlined
        type="error"
        v-if="subscriptionErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ subscriptionErrorMessage }}
      </v-alert>
      <v-alert
        text
        outlined
        type="info"
        v-if="subscriptionMessageForManualPaymentMethod && !brandDeletionMessage"
        class="mt-4 col-12 text-center"
      >
        {{ subscriptionMessageForManualPaymentMethod }}
      </v-alert>
      <v-alert
        text
        outlined
        type="info"
        color="green"
        v-if="trialPeriod"
        class="mt-4 col-12 text-center"
      >
        {{ $t('yourTrialPeriodWillEndIn') }} {{ remainingTrialPeriod }}.
      </v-alert>
      <v-alert
        text
        outlined
        type="error"
        v-if="!!brandDeletionMessage"
        class="col-12 text-center"
      >
        <span>
          {{ brandDeletionMessage }}
        </span>
        <span class="font-weight-bold"> : support@cm-technology.io </span>
      </v-alert>
      <v-card-title
        v-if="brandSubscription"
      >
        <span>{{ $t('Subscription') }}</span><span v-if="brand?.subscription?.price === 0">&nbsp;({{ $t('free') }})</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            !brandDeletionMessage && !subscriptionMessageForManualPaymentMethod
          "
          color="error"
          class="text-none"
          @click="modalCancelSubscription = true"
        >
          <v-icon small left>
            fas fa-lock
          </v-icon>
          <template v-if="brand?.subscription?.price === 0">
            {{ $t('disabledThisWarehouse') }}
          </template>
          <template v-else>
            {{ $t('CancelSubscription') }}
          </template>
        </v-btn>
        <v-btn
          v-else-if="
            brandDeletionMessage && !subscriptionMessageForManualPaymentMethod
          "
          color="error"
          class="text-none ml-3"
          @click="modalReactivateSubscription = true"
        >
          <v-icon small left>
            fas fa-lock-open
          </v-icon>
          {{ $t('ReactivateSubscription') }}
        </v-btn>
      </v-card-title>

      <!-- DIALOG CANCEL SUBSCRIPTION -->
      <v-dialog v-model="modalCancelSubscription" max-width="450px">
        <v-card>
          <v-card-title v-if="brand?.subscription?.price === 0">
            {{ $t('yourWarehouseIsFreeDeactivate') }} ?
          </v-card-title>
          <v-card-title v-else>
            {{ $t('CancelSubscription') }} ?
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="brand?.subscription?.price === 0" class="body-1 py-4 text-justify">
            <p>{{ $t('confirmDeactivate') }}</p>
          </v-card-text>
          <v-card-text v-else class="body-1 py-4 text-justify">
            <div>{{ $t('confirmCancelSubscription') }} :</div>
            <div
              v-if="brandSubscription"
              class="font-weight-bold text-center pt-2"
            >
              <span v-if="!brandSubscription.endTrialPeriod">
                {{ brandSubscription.readableNextBillingDate }}
              </span>
              <span v-else>
                {{ brandSubscription.endTrialPeriod }}
              </span>
            </div>
            <div class="pt-2">
              {{ $t('confirmCancelSubscription2') }}
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="error"
              class="text-none"
              @click="modalCancelSubscription = false"
            >
              <v-icon small left>
                fas fa-ban
              </v-icon>
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none"
              @click="deactivateBrandSubscription"
            >
              <v-icon small left>
                fas fa-check
              </v-icon>
              {{ $t('Confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END DIALOG CANCEL SUBSCRIPTION -->
      <!-- DIALOG REACTIVATE SUBSCRIPTION -->
      <v-dialog v-model="modalReactivateSubscription" max-width="450px">
        <v-card>
          <v-card-title> {{ $t('ReactivateSubscription') }} ? </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="body-1 py-4 text-justify">
            <div>{{ $t('confirmContinueSubscription') }} :</div>
            <div
              v-if="brandSubscription"
              class="font-weight-bold text-center pt-2"
            >
              <span v-if="!brandSubscription.endTrialPeriod">
                {{ brandSubscription.readableNextBillingDate }}
              </span>
              <span v-else>
                {{ brandSubscription.endTrialPeriod }}
              </span>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="error"
              class="text-none"
              @click="modalReactivateSubscription = false"
            >
              <v-icon small left>
                fas fa-ban
              </v-icon>
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none"
              @click="reactivateBrandSubscription"
            >
              <v-icon small left>
                fas fa-check
              </v-icon>
              {{ $t('Confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END DIALOG REACTIVATE SUBSCRIPTION -->
      <v-col cols="12" md="6">
        <v-card-text
          class="pt-0"
          v-if="brandSubscription"
        >
          <v-row class="body-1">
            <div>
              <span class="font-weight-bold"> {{ $t('Offer') }} : </span>
              <span class="text-capitalize">
                {{ $t(getSubscriptionTitle(brandSubscription.plan)) }}
              </span>
            </div>
          </v-row>
          <v-row class="pt-3 body-1" v-if="brand?.subscription?.price > 0">
            <div>
              <span class="font-weight-bold"> {{ $t('Recurrence') }} : </span>
              <span>
                {{ $t(brandSubscription.recurrence) }}
              </span>
            </div>
          </v-row>
          <v-row v-if="brandSubscription.payment_method !== 'MANUAL' && brand?.subscription?.price > 0" class="pt-3 body-1">
            <div>
              <span class="font-weight-bold"> {{ $t('PaymentMethod') }} : </span>
              <span>
                {{ $t(brandSubscription.payment_method) }}
              </span>
            </div>
          </v-row>
          <template v-if="brandSubscription.payment_method !== 'MANUAL' && brand?.subscription?.price > 0">
            <v-row class="pt-3 body-1" v-if="!trialPeriod">
              <div>
                <span class="font-weight-bold">
                  {{ $t('nextBillingDate') }} :
                </span>
                <span>
                  {{ brandSubscription.readableNextBillingDate }}
                </span>
              </div>
            </v-row>
            <v-row class="pt-3 body-1" v-else>
              <div>
                <span class="font-weight-bold">
                  {{ $t('endTrialPeriod') }} :
                </span>
                <span>
                  {{ brandSubscription.readableNextBillingDate }}
                </span>
              </div>
            </v-row>
          </template>
          <v-row
            class="pt-3 body-1"
            v-if="
              brandSubscription && brandSubscription.discountsArray.length > 0
            "
          >
            <div>
              <span class="font-weight-bold"> {{ $t('Discount') }} : </span>
              <span>
                {{ brandSubscription.discountsArray[0].discount }}
              </span>
            </div>
          </v-row>
          <v-row class="pt-3 body-1">
            <div>
              <span class="font-weight-bold"> {{ $t('PriceET') }} : </span>
              <span v-if="brandSubscription.discountsArray.length === 0">
                {{ $n(brandSubscription.price.toFixed(2), 'currency') }}
              </span>
              <span v-else>
                <span class="text-strikethrough">
                  {{ $n(brandSubscription.price.toFixed(2), 'currency') }}
                </span>
                <span class="ml-2 title orange--text">
                  {{ $n(brandSubscription.discountsArray[0].price, 'currency') }}
                </span>
              </span>
            </div>
          </v-row>
          <v-row class="pt-3 body-1" v-if="brandSubscription.purchaseOrder">
            <div>
              <span class="font-weight-bold">
                {{ $t('purchaseOrder') }} :
              </span>
              <span>
                {{ brandSubscription.purchaseOrder }}
              </span>
            </div>
          </v-row>
        </v-card-text>
      </v-col>

      <v-card
        flat
        class="mt-10"
        v-if="
          brandSubscription && brandSubscription.discountsArray.length > 0
        "
      >
        <v-card-title v-if="brandSubscription.recurrence === 'MONTHLY'">
          {{ $t('yourMonthlyDiscounts') }}
        </v-card-title>
        <v-card-title v-if="brandSubscription.recurrence === 'YEARLY'">
          {{ $t('yourYearlyDiscounts') }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDiscounts"
            :hide-default-footer="true"
            :items="brandSubscription.discountsArray"
            mobile-breakpoint="0"
            :footer-props="{
              itemsPerPageOptions: [15, 50, 100, -1],
            }"
          >
            <template v-slot:[`item`]="{ item }">
              <tr
                :class="{
                  'font-weight-bold':
                    item === brandSubscription.discountsArray[0],
                }"
              >
                <td>
                  {{ item.discount }}
                </td>
                <td>{{ item.price }}€</td>
                <td v-if="item.duration !== -1" class="text-center">
                  {{ item.duration }}
                </td>
                <td v-else class="text-center">
                  {{ $t('lifetime') }}
                </td>
                <td class="text-center">
                  {{ item.start }}
                </td>
                <td v-if="item.duration !== -1" class="text-center">
                  {{ item.end }}
                </td>
                <td v-else class="text-center">
                  -
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
    <!--
        ---
        --- INVOICES LIST
        ---
        -->
    <v-card
      v-if="subscriptionInvoices && subscriptionInvoices.length > 0"
      flat
      class="px-5 ml-sm-3 mt-10"
    >
      <v-card-title>
        {{ $t('invoices') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="subscriptionInvoices"
          :headers="headersInvoices"
          :items="subscriptionInvoices"
          :items-per-page="10"
          sort-by="date"
          mobile-breakpoint="0"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1],
          }"
        >
          <template v-slot:[`item.date`]="{ item }">
            <template>
              <span> {{ item.readableCreated }} </span>
            </template>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <template>
              <span>{{ item.readablePrice }} </span>
            </template>
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <template>
              <a class="d-none" :ref="`download${item._id}`"></a>
              <v-btn
                color="orange"
                class="text-none white--text"
                target="_blank"
                :loading="item.loadingPdf"
                :disabled="item.disabled"
                @click="getSubscriptionInvoicePDF(item)"
                :ripple="false"
              >
                <v-icon small class="mr-1">
                  fas fa-download
                </v-icon>
                PDF
              </v-btn>
            </template>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="brandSubscription && brandSubscription.payment_method === 'CARD'">
              <template v-if="item.paymentStatus === 'PAID'">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="v-icon" v-on="on">
                      <v-icon
                        color="green"
                        class="mr-1"
                      >
                        far fa-credit-card
                      </v-icon>
                      <v-icon color="green">
                        fas fa-check
                      </v-icon>
                    </div>
                  </template>
                  <span> {{ $t('Paid') }} </span>
                </v-tooltip>
              </template>
              <template v-else-if="item.paymentStatus === 'WAITING'">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="v-icon" v-on="on">
                      <v-icon
                        color="black"
                        class="mr-1"
                      >
                        far fa-credit-card
                      </v-icon>
                      <v-icon color="black">
                        fas fa-ellipsis-h
                      </v-icon>
                    </div>
                  </template>
                  <span> {{ $t('InProgress') }} </span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="v-icon" v-on="on">
                      <v-icon color="red" class="mr-2">
                        far fa-credit-card
                      </v-icon>
                      <v-icon
                        color="red"
                        class="mr-5"
                      >
                        fas fa-exclamation-triangle
                      </v-icon>
                    </div>
                  </template>
                  <span> {{ $t('ErrorPayment') }} </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      v-on="on"
                      overlap
                      bordered
                      icon="fas fa-pen"
                      class="mr-5"
                    >
                      <v-btn
                        small
                        outlined
                        color="info"
                        @click="manualPayment(item)"
                        v-on="on"
                      >
                        <v-icon small>
                          far fa-credit-card
                        </v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span> {{ $t('ManualPayment') }} </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      v-on="on"
                      overlap
                      bordered
                      icon="fas fa-plus"
                    >
                      <v-btn
                        small
                        outlined
                        color="info"
                        :to="{name: 'AdministrationPaymentMethods', params: { brandUUID: `${$appConfig.currentBrand}`}}"
                        v-on="on"
                      >
                        <v-icon small>
                          far fa-credit-card
                        </v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span> {{ $t('AddOrChangeCard') }} </span>
                </v-tooltip>
              </template>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!--
        ---
        --- MODAL PAYMENT
        ---
        --->
    <v-dialog
      v-if="client_key"
      v-model="modalPayment"
      persistent
      max-width="750px"
    >
      <v-card min-height="300px">
        <v-card-title class="headline">
          <v-col align="center">
            {{ $t('PayByCard') }}
          </v-col>
          <v-btn
            icon
            :ripple="false"
            @click="
              modalPayment = false
              client_key = null
            "
            style="position: absolute; right: 15px"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <stripe-payment
            :api-key="$stripeConfig.pk"
            :client-secret="client_key"
            @paid="paymentFinished"
          >
          </stripe-payment>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  EventBus, 
  ApiErrorParser, 
  StripePayment
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageAdministrationSubscription.i18n.json');

export default {
  name: 'PageAdministrationSubscription',
  components: { StripePayment },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandUserRolesErrorMessage: null,
      subscriptionErrorMessage: null,
      subscriptionMessageForManualPaymentMethod: null,
      loading: false,
      disabled: true,
      brand: null,
      brandSubscription: null,
      brandUserRoles: null,
      brandUserRolesAdminAccessErrorMessage: '',
      loadingPayments: true,
      subscriptionInvoices: [],
      headersInvoices: null,
      tabNum: 0,
      currentSubscriptionInvoiceId: null,
      headersDiscounts: [],
      remainingTrialPeriod: null,
      modalCancelSubscription: false,
      modalReactivateSubscription: false,
      brandDeletionMessage: null,
      trialPeriod: false,
      client_key: null,
      modalPayment: false,
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }

    this.headersInvoices = [
      { text: this.$t('billingDate'), value: 'date', sortable: false },
      { text: this.$t('Price'), value: 'price', sortable: false },
      {
        text: this.$t('Download'),
        value: 'download',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('status'),
        value: 'status',
        sortable: false,
        align: 'center',
      },
    ];
    this.headersDiscounts = [
      { text: this.$t('Discount'), value: 'discount', sortable: false },
      { text: this.$t('PriceET'), value: 'price', sortable: false },
      {
        text: this.$t('durationMonths'),
        value: 'duration',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('start'),
        value: 'start',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('end'),
        value: 'end',
        sortable: false,
        align: 'center',
      },
    ];
    this.$apiInstance
      .getBrandUserRoles(
        this.$appConfig.currentBrand,
        this.$appConfig.user._id
      )
      .then(
        (brandUserRolesData) => {
          this.brandUserRoles = brandUserRolesData;
          /**
           * GET BRAND
           */
          this.getBrand();
        },
        (error) => {
          this.brandUserRolesErrorMessage = ApiErrorParser.parser(error);
        }
      );
  },
  methods: {
    getBrand() {
      /**
       * GET BRAND
       */
      this.loading = true;
      this.$apiInstance
        .getBrand(this.$appConfig.currentBrand)
        .then(
          (brandData) => {
            this.brand = brandData;
            if (this.brand.invalid_after && this.brand.invalid_after !== '') {
              const deletionDate = this.getReadableDate(
                new Date(this.brand.invalid_after)
              );
              this.brandDeletionMessage = this.$t('deletionMessage', {
                deletionDate: deletionDate,
              });
            }
            /**
             * GET BRAND USER ROLES
             */           
            if (!this.brand?.roles?.admin?.includes(this.$appConfig.user._id) && !this.brandUserRoles.includes('admin')) {
              this.brandUserRolesAdminAccessErrorMessage = this.$t(
                'needToBeAdmin'
              );
              this.disabled = true;
            }
            /**
             * GET BRAND SUBSCRIPTION
             */
            this.getBrandSubscription();
            /**
             *  GET BRAND SUBSCRIPTION INVOICES
             */
            this.getSubscriptionInvoices();
            this.disabled = false;
          },
          (error) => {
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * GET BRAND SUBSCRIPTION
     */
    getBrandSubscription() {
      const subscription = { ...this.brand.subscription };

      subscription.readableNextBillingDate = subscription.nextBillingDate
        ? this.getReadableDate(new Date(subscription.nextBillingDate))
        : null;

      let dateNextBillingDate = new Date(subscription.nextBillingDate);

      dateNextBillingDate = new Date(
        dateNextBillingDate.getTime() +
          dateNextBillingDate.getTimezoneOffset() * 1000 * 60
      );

      /**
          subscription.endTrialPeriod = this.getReadableDate(
            new Date(dateNextBillingDate.setDate(dateNextBillingDate.getDate()))
          );**/

      const oneHour = 1000 * 60 * 60;
      const oneDay = oneHour * 24;

      const numberOfDays = Math.floor(
        (dateNextBillingDate - new Date()) / oneDay
      );

      this.trialPeriod =
        subscription.trial_period > 0 && dateNextBillingDate > new Date();

      if (numberOfDays > 0) {
        if (numberOfDays === 1) {
          this.remainingTrialPeriod = `${numberOfDays} ${this.$t('day')}`;
        } else {
          this.remainingTrialPeriod = `${numberOfDays} ${this.$t('days')}`;
        }
      } else {
        const numberOfHours = Math.floor(
          (dateNextBillingDate - new Date()) / oneHour
        );
        if (numberOfHours > 0) {
          if (numberOfHours === 1) {
            this.remainingTrialPeriod = `${numberOfHours} ${this.$t(
              'hour'
            )}`;
          } else {
            this.remainingTrialPeriod = `${numberOfHours} ${this.$t(
              'hours'
            )}`;
          }
        } else {
          this.remainingTrialPeriod = `${this.$t('lessThanOneHour')}`;
        }
      }

      this.brandSubscription = subscription;
      this.brandSubscription['discountsArray'] = [];

      const keys = subscription.discounts
        ? Object.keys(subscription.discounts)
        : [];

      keys.sort(function(a, b) {
        return parseInt(b) - parseInt(a);
      });

      let lifetime = false;

      const currentStartPeriod = new Date(dateNextBillingDate);

      for (const key of keys) {
        if (lifetime) {
          break;
        }
        if (this.brandSubscription.discounts[key] === -1) {
          lifetime = true;
        }
        this.brandSubscription.discountsArray.push({
          discount: key + '%',
          price: (
            (1 - parseInt(key) / 100) *
            this.brandSubscription.price
          ).toFixed(2),
          duration:
            subscription.discounts[key] > 0
              ? subscription.discounts[key]
              : -1,
          start: `${('0' + (currentStartPeriod.getMonth() + 1)).slice(-2)} / ${currentStartPeriod.getFullYear()}`,
          end: this.brandSubscription.recurrence === 'MONTHLY' ? `${(
            '0' +
            (new Date(
              currentStartPeriod.setMonth(
                currentStartPeriod.getMonth() + subscription.discounts[key]
              )
            ).getMonth() +
              1)
          ).slice(-2)}
                /
                ${currentStartPeriod.getFullYear()}`
            : `${('0' + (currentStartPeriod.getMonth() + 1)).slice(-2)} / ${new Date(currentStartPeriod.setFullYear(currentStartPeriod.getFullYear() + subscription.discounts[key])).getFullYear()}`,
          lifetime: subscription.discounts[key] === -1,
        });
        this.idDiscount += 1;
      }

      if (this.brandSubscription.payment_method === 'MANUAL') {
        this.subscriptionMessageForManualPaymentMethod = this.$t(
          'subscriptionMessageManualPaymentMethod'
        );
      }
    },
    getReadableDate(dateObject) {
      const day = dateObject.getDate();
      const month = dateObject.getMonth();
      const year = dateObject.getFullYear();
      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];
      return day + ' ' + this.$t(months[month]) + ' ' + year;
    },
    manualPayment(subscriptionInvoiceItem) {
      this.$apiInstance
        .getBrandSubscriptionInvoiceClientSecret(
          subscriptionInvoiceItem.brand,
          subscriptionInvoiceItem._id
        )
        .then((data) => {
          this.client_key = data;
          this.modalPayment = true;
          this.currentSubscriptionInvoiceId = subscriptionInvoiceItem._id;
        });
    },
    paymentFinished() {
      this.$apiInstance
        .updateBrandSubscriptionInvoicePaymentStatus(
          this.$appConfig.currentBrand,
          this.currentSubscriptionInvoiceId
        )
        .then(() => {
          this.currentSubscriptionInvoiceId = null;
          this.client_key = null;
          this.modalPayment = false;
          this.getSubscriptionInvoices();
        });
    },
    /**
     * GET SUBSCRIPTION INVOICES
     */
    getSubscriptionInvoices() {
      this.subscriptionInvoices = [];
      this.$apiInstance
        .getBrandSubscriptionInvoices(this.$appConfig.currentBrand)
        .then((data) => {
          data.forEach((invoice) => {
            invoice.readableCreated = this.getReadableDate(
              new Date(invoice.created)
            );
            invoice.readablePrice = this.getReadablePrice(
              invoice.quickbooksObject
            );
            invoice.disabled = false;
            invoice.loadingPdf = false;
            invoice.pdfData = null;
          });
          this.subscriptionInvoices = data.filter(invoice => {
            return !!invoice.quickbooksObject && invoice.quickbooksObject.TotalAmt >= 0;
          });
        });
    },
    getReadablePrice(qboObject) {
      if (!qboObject || !qboObject.TotalAmt) {
        return this.$t('ManualPayment');
      }
      return qboObject.TotalAmt.toFixed(2).toString() + ' €';
    },
    /**
     * GET SUBSCRIPTION INVOICE PDF
     */
    getSubscriptionInvoicePDF(subscriptionInvoiceItem) {
      subscriptionInvoiceItem.loadingPdf = true;
      /**
       * GET SUBSCRIPTION INVOICE PDF
       */
      this.$apiInstance
        .getBrandSubscriptionInvoicePDF(
          subscriptionInvoiceItem.brand,
          subscriptionInvoiceItem._id
        )
        .then(
          (pdf) => {
            const aRef = `download${subscriptionInvoiceItem._id}`;
            this.$refs[
              aRef
            ].download = `Subscription_Invoice_Beelse_${subscriptionInvoiceItem.quickbooksObject.DocNumber}`;
            this.$refs[aRef].href = `data:application/pdf;base64,${pdf.data}`;

            this.$refs[aRef].click();
            subscriptionInvoiceItem.disabled = false;
          },
          (error) => {
            /**
             * ERROR GET QUOTE PDF
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            subscriptionInvoiceItem.disabled = true;
          }
        )
        .finally(() => {
          subscriptionInvoiceItem.loadingPdf = false;
        });
    },
    /**
     * DESACTIVE BRAND SUBSCRIPTION
     */
    deactivateBrandSubscription() {
      this.$apiInstance.deactivateBrand(this.brand._id).then(
        (data) => {
          this.modalCancelSubscription = false;
          this.getBrand();
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    /**
     * REACTIVE BRAND SUBSCRIPTION
     */
    reactivateBrandSubscription() {
      this.$apiInstance.cancelBrandDeactivation(this.brand._id).then(
        (data) => {
          this.brandDeletionMessage = false;
          this.modalReactivateSubscription = false;
          this.getBrand();
        },
        (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    /**
     * Temporary format Brand Plan
     */
    getSubscriptionTitle(plan) {
      /**
       * Temporary fix for subscription until the attribute title will be available
       */
      let title = '';
      switch (plan) {
      case 'CMT':
        title = 'CMT';
        break;
      case 'ORGANIZATION_MONTHLY':
        title = 'CMT Organization Plus';
        break;
      case 'ORGANIZATION_YEARLY':
        title = 'CMT Organization Plus';
        break;
      case 'PRODUCTION_MONTHLY':
        title = 'CMT Production Plus';
        break;
      case 'PRODUCTION_YEARLY':
        title = 'CMT Production Plus';
        break;
      case 'ENTERPRISE_MONTHLY':
        title = 'CMT Enterprise';
        break;
      case 'ENTERPRISE_YEARLY':
        title = 'CMT Enterprise';
        break;
      default:
        title = plan.replace('_',' ');
        break;
      }
      return title;
    },
  },
};
</script>
